import { $axios, $axiosCustom, $axiosFileUpload } from './axios';
import { AxiosResponse } from 'axios';
// import SnackbarUtils from '../utils/SnackbarUtilsConfigurator';

import {
  API_BASE_URL,
  API_TP_BASE_URL,
  API_IDENTITY_SERVER_URL,
} from '../constants';

import {
  parseJwt
} from '../utils/';

$axios.defaults.baseURL = `${API_BASE_URL}/`;

export interface IApiResponse {
    data?: any;
}

// We use this for requests which don't use our default headers
export const ApiServiceCustom = {
  post(resource: string, params: any, config?: {[key: string]: any}) {
    return $axiosCustom.post(`${resource}`, params, config);
  },
}

export const ApiServiceFileUploads = {
  post(resource: string, params: any, config?: {[key: string]: any}) {
    return $axiosFileUpload.post(`${resource}`, params, config);
  },
}

export const ApiService = {

  query(resource: string, params: object) {
      return $axios.get(resource, {params}).catch(error => {
          throw new Error(`ApiService ${error}`);
      });
  },

  get(resource: string, slug = "") {
    return $axios.get(`${resource}/${slug}`).catch(error => {
        // SnackbarUtils.error('API connection unavailable');
        throw new Error(`ApiService ${error}`);
    });
  },

  post(resource: string, params: any, config?: {[key: string]: any}) {
      return $axios.post(`${resource}`, params, config);
  },

  patch(url: string, data: any, config?: {[key: string]: any}) {
    return $axios.patch(url, data, config);
  }

  // update(resource, slug, params) {
  //     return $axios.put(`${resource}/${slug}`, params);
  // },

  // put(resource, params) {
  //     return $axios.put(`${resource}`, params);
  // },

  // delete(resource) {
  //     return $axios.delete(resource).catch(error => {
  //         throw new Error(`ApiService ${error}`);
  //     });
  // }
};

interface IAgentFlowPatch {
  Type?: number
  Location?: ILocationPatch
  DealsClosed?: string
  FirstName?: string
  LastName?: string
  Email?: string
  PhoneNumber?: string
}

interface ILocationPatch {
  placeId?: string
  placeDesc: string 
}

interface IBuyerFlowPatch {
  Type?: number
  Actions?: number[]
  Location?: ILocationPatch
  FirstName?: string
  LastName?: string
  Email?: string
  PhoneNumber?: string
  Purchase?: string
  PriceRange?: string
  HasAgent?: boolean
  AgentEmail?: string
  AgentPhone?: string
  AgentFirstName?: string
  AgentLastName?: string
  MessageToAgent?: string
}

export const FlowService = {
  async patchSession(sessionId: string, data: IAgentFlowPatch | IBuyerFlowPatch, captchaToken: string) {
    return ApiService.patch(`v3/leads/${sessionId}`, data, {
      headers: {
        "captcha-code": captchaToken
      }
    });
  },
}

interface IRegisterUser {
  email: string;
  phoneNumber: string;
  isAgent: boolean;
  password?: string;
  firstName?: string;
  lastName?: string;
  leadUUID?: string;
}

export const UserService = {
  async getPhoneInfo(phoneNumberWithoutPrefix: string, dialCode: string) {
    // Example: with phoneNumber of +27 84 321 1234
    // phoneNumberWithoutPrefix: 843211234
    // dialCode: 27
    return ApiService.post(`v2/users/getphoneinfo`, {
      phoneNumber: phoneNumberWithoutPrefix,
      dialCode,
    })
  },
  async phoneExists(phoneNumberWithoutPrefix: string, dialCode: string, countryCode: string) {
    // Example: with phoneNumber of +27 84 321 1234
    // phoneNumberWithoutPrefix: 843211234
    // dialCode: 27
    // countryCode: ZA
    return ApiService.post(`v3.1/users/phoneExists`, {
      phoneNumber: phoneNumberWithoutPrefix,
      dialCode,
      countryCode,
    })
  },
  async canRegister(email: string) : Promise<AxiosResponse["data"]> {
    return ApiService.post(`v3/users/canregister`, {
      email
    })
  },
  async getLatestNotifications() : Promise<AxiosResponse["data"]> {
    return ApiService.get(
      `v3/users/notifications`,
    )
  },
  async registerUser(registrationData: IRegisterUser, captchaToken: string) : Promise<AxiosResponse["data"]> {
    return ApiService.post(`v3/users/register`, registrationData, {
      headers: {
        "captcha-code": captchaToken
      }
    })
  },
  async loginFreshlyUnclaimedUser(email: string) : Promise<AxiosResponse["data"]> {
    const loginFormData = new URLSearchParams();
    loginFormData.append('grant_type', 'unclaimed_account');
    loginFormData.append('client_id', 'propy-web');
    loginFormData.append('username', email);
    let initialTokenResponse = await ApiServiceCustom.post(
      `connect/token`,
      loginFormData,
      {
        baseURL: API_IDENTITY_SERVER_URL,
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    );
    let refreshToken = initialTokenResponse.data.refresh_token;
    const params = new URLSearchParams();
    params.append('refresh_token', refreshToken);
    params.append('grant_type', 'refresh_token');
    params.append('client_id', 'propy-web');
    let finalTokenResponse = await ApiServiceCustom.post(
      `connect/token`,
      params,
      {
        baseURL: API_IDENTITY_SERVER_URL,
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    );
    console.log({finalTokenResponse})
    return {
      ...finalTokenResponse.data,
      profile: parseJwt(finalTokenResponse.data.id_token),
      auth_time: Math.floor(new Date().getTime() / 1000)
    };
  },
  async getUserProfile() : Promise<AxiosResponse["data"]> {
    return ApiService.get(
      `Users/single`,
    )
  },
  async uploadUserContract(files: File[], docIds: string[], fileType: string) : Promise<AxiosResponse["data"]> {
    return ApiServiceFileUploads.post(`documents/startExtractData`, {
      Files: files,
      // DocIds: docIds,
      // DocumentType: fileType,
    },
    {
      baseURL: API_TP_BASE_URL,
      'Content-Type': 'multipart/form-data',
    })
  },
  async publicUploadContract(files: File[], captchaToken: string) : Promise<AxiosResponse["data"]> {
    return ApiServiceFileUploads.post(`documents/upload/public`, {
      Files: files,
    },
    {
      baseURL: API_TP_BASE_URL,
      headers: {
        "captcha-code": captchaToken,
        'Content-Type': 'multipart/form-data',
      }
    })
  },
}