import React, { useEffect, useState, useCallback } from 'react';

import { toast } from 'sonner'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { useDropzone } from 'react-dropzone'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudUploadCompleteIcon from '@mui/icons-material/CloudDone';
import CircularProgress from '@mui/material/CircularProgress';

import FloatingActionButton from './FloatingActionButton';

import { PropsFromRedux } from '../containers/DropContractUploadContainer';

import useRecaptchaToken from '../hooks/useRecaptchaToken';

import {
  storeAuthTokens,
  IdentityServerPropyAuthority
} from '../services/auth';

import { 
  UserService,
} from '../services/api';

import {
  BASE_URL,
  PROPY_BLUE
} from '../constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      maxWidth: 600,
    },
    desktopPadding: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
    mobilePadding: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    desktopPaddingExpanded: {
      padding: theme.spacing(8),
    },
    mobilePaddingExpanded: {
      padding: theme.spacing(4),
    },
    fileIcon: {
      width: 80,
      height: 80,
      opacity: 0.5,
      marginBottom: theme.spacing(1),
    },
    fileIconReadyForUpload:{
      width: 80,
      height: 80,
      opacity: 0.5,
      marginBottom: theme.spacing(1),
      color: PROPY_BLUE,
    },
    uploadingIcon: {
      width: 50,
      height: 50,
      opacity: 0.5,
      marginBottom: theme.spacing(1),
      color: PROPY_BLUE,
    },
    uploadButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    errorMessage: {
      fontWeight: 'bold',
      fontSize: '0.9rem',
      color: 'red',
    },
    warnMessage: {
      fontWeight: 'bold',
      fontSize: '0.9rem',
      color: '#ff4500',
    },
    uploadIconsContainer: {
      width: '80px',
      height: '80px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    uploadSpinner: {
      width: '70px',
      height: '70px',
      position: 'absolute',
    },
    uploadButtonSubtitle: {
      marginTop: theme.spacing(2),
      fontWeight: 400,
    },
    typographySpacing: {
      marginTop: theme.spacing(1),
    },
    typographySpacingExpanded: {
      marginTop: theme.spacing(2),
    }
  }),
);

interface IDropContractUploadProps {
  expanded?: boolean;
  recaptchaRef: any;
}

const getButtonText = (isUploading: boolean, isUploadComplete: boolean) => {
  let buttonText = "Next";
  if(isUploading) {
    buttonText = "Uploading...";
  }
  if(isUploadComplete) {
    buttonText = "Upload complete";
  }
  return buttonText;
}

const DropContractUpload = (props: IDropContractUploadProps & PropsFromRedux) => {
    const classes = useStyles();

    const [isCheckingEncryption, setIsCheckingEncryption] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<false | string>(false);
    const [warnMessage, setWarnMessage] = useState<false | string>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);

    const {
      isConsideredMobile,
      expanded = false,
      recaptchaRef,
      signedIn = false,
    } = props;

    const checkIfPdfIsEncrypted = (file: File): Promise<boolean> => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const uint8Array = new Uint8Array(e.target?.result as ArrayBuffer);
          const pdfHeader = uint8Array.slice(0, 5);
          const isPdf = String.fromCharCode.apply(null, pdfHeader as unknown as number[]) === '%PDF-';
          
          if (!isPdf) {
            resolve(false);
            return;
          }

          // Check for encryption dictionary
          const pdfString = String.fromCharCode.apply(null, uint8Array as unknown as number[]);
          const hasEncryptDict = /\/Encrypt\s+\d+\s+\d+\s+R/.test(pdfString);
          
          resolve(hasEncryptDict);
        };
        reader.readAsArrayBuffer(file.slice(0, 1024)); // Read first 1KB of the file
      });
    };

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        setIsCheckingEncryption(true);
        const file = acceptedFiles[0];
        const isEncrypted = await checkIfPdfIsEncrypted(file);
        if (isEncrypted) {
          let passwordProtectedErrorMessage = "The selected PDF appears to be password protected. Please ensure you are picking an unprotected file for upload.";
          setWarnMessage(passwordProtectedErrorMessage);
          toast.error(passwordProtectedErrorMessage);
        } else {
          setWarnMessage(false);
        }
        setIsCheckingEncryption(false);
      }
    }, []);

    const getToken = useRecaptchaToken(recaptchaRef);

    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      isDragActive,
    } = useDropzone({
      accept: {
        'application/pdf': [],
      },
      maxFiles: 1,
      onDrop,
    });

    useEffect(() => {
      setIsUploadComplete(false);
      setIsUploading(false);
      if (fileRejections?.[0]?.errors?.[0]?.message) {
        let overrideErrorMessage = fileRejections[0].errors[0].message;
        if (overrideErrorMessage === "Too many files") {
          overrideErrorMessage = "Please only select one contract, more can be added later."
        }
        if (overrideErrorMessage === "File type must be application/pdf") {
          overrideErrorMessage = "Please only select a .pdf file."
        }
        setErrorMessage(overrideErrorMessage)
      } else {
        setErrorMessage(false);
      }
    }, [fileRejections, acceptedFiles])

    const startUpload = async () => {
      setIsUploadComplete(false);
      setIsUploading(true);
      if(acceptedFiles && acceptedFiles?.length === 1) {
        if(!signedIn) {
          if(!recaptchaRef?.current) {
            toast.error("Recaptcha expired, please refresh the page.");
            return;
          }
          try {
            const recaptchaToken = await getToken();
            let startExtractResponse = await UserService.publicUploadContract(acceptedFiles, recaptchaToken);
            setIsUploading(false);
            console.log({'startExtractResponse?.data': startExtractResponse?.data});
            if(startExtractResponse?.data?.length > 0) {
              let { docId } = startExtractResponse?.data?.[0];
              if(docId) {
                setIsUploadComplete(true);
                let redirectUrl = `${BASE_URL}/signup?isAgent=true&analyzeDocId=${docId}`
                window.location.href = redirectUrl;
                return;
              } else {
                setIsUploadComplete(true);
                toast.error("Something went wrong");
              }
            }
          } catch (e: any) {
            toast.error(e?.message ? e.message : e);
          }
        } else {
          let idServer = new IdentityServerPropyAuthority();
          let refreshTokensResponse = await idServer.refreshTokens() as any;
          let {
            refresh_token,
            access_token,
            expires_in,
            auth_time,
            profile,
            scope,
            token_type,
          } = refreshTokensResponse;
          await storeAuthTokens(access_token, refresh_token, expires_in, auth_time);
          await idServer.setUser({
            access_token,
            refresh_token,
            token_type,
            scope,
            profile,
            expires_at: (Number(auth_time) + expires_in).toString(),
          })
          try {
            let startExtractResponse = await UserService.uploadUserContract(acceptedFiles, acceptedFiles.map((acceptedFile: File) => acceptedFile.name), 'application/pdf');
            setIsUploading(false);
            if(startExtractResponse?.data?.documentJobs) {
              let documentJobEntries = Object.entries(startExtractResponse?.data?.documentJobs);
              let [docId, jobId] = documentJobEntries[0];
              if(docId && jobId) {
                setIsUploadComplete(true);
                let redirectUrl = `${BASE_URL}/order?externalAccessToken=${access_token}&docId=${docId}&jobId=${jobId}`
                window.location.href = redirectUrl;
                return;
              } else {
                setIsUploadComplete(true);
                toast.error("Something went wrong");
              }
            }
          } catch (e: any) {
            toast.error(e?.message ? e.message : e);
          }
        }
      } else {
        toast.error("Valid contract not found or too many contracts selected");
      }
      setIsUploading(false);
      setIsUploadComplete(false);
    }

    const {ref, ...rootProps} = getRootProps()

    const getPaddingClass = () => {
      if(expanded) {
        return isConsideredMobile ? classes.mobilePaddingExpanded : classes.desktopPaddingExpanded;
      }
      return isConsideredMobile ? classes.mobilePadding : classes.desktopPadding;
    }

    return (
      <div>
        <Paper 
          style={{
            border: isDragActive ? `2px dashed ${PROPY_BLUE}` : `2px ${acceptedFiles?.length > 0 ? `solid ${PROPY_BLUE}` : 'dashed #dcdcdc'}`,
            cursor: (acceptedFiles && (acceptedFiles?.length > 0)) ? 'auto' : 'pointer',
          }}
          {...rootProps}
          ref={ref}
          className={[classes.root, getPaddingClass()].join(" ")}
          {...getRootProps({
            onClick: (event: any) => {
              if(acceptedFiles && (acceptedFiles?.length > 0)) {
                event.preventDefault();
                event.stopPropagation();
              }
            },
          })}
        >
          <input {...getInputProps()} />
          {(!acceptedFiles || (acceptedFiles?.length === 0) || isCheckingEncryption) &&
            <>
              <FilePresentIcon className={classes.fileIcon} />
              <Typography className={expanded ? classes.typographySpacingExpanded : ""}>Drop an executed contract or <b style={{color: PROPY_BLUE, cursor: 'pointer'}}>choose a file</b> to upload.</Typography>
              <Typography className={expanded ? classes.typographySpacingExpanded : classes.typographySpacing}>Immediately after you provide a signed purchase agreement in FL/CO/AZ, the escrow will be opened by our closing team.</Typography>
            </>
          }
          {acceptedFiles && (acceptedFiles?.length === 1) && !isCheckingEncryption &&
            <>
              {(!isUploading && !isUploadComplete) &&
                <>
                  <FilePresentIcon className={classes.fileIconReadyForUpload} />
                  <Typography className={expanded ? classes.typographySpacingExpanded : ""}>Propy AI is ready to read your contract.</Typography>
                </>
              }
              {(isUploading && !isUploadComplete) &&
                <>
                  <div className={classes.uploadIconsContainer}>
                    <CircularProgress sx={{
                      '& .MuiCircularProgress-svg': {
                        width: 70, height: 70,
                      }}} size={70} style={{width: 70, height: 70, position: 'absolute'}} thickness={1}/>
                    <CloudUploadIcon className={classes.uploadingIcon} />
                  </div>
                  <Typography className={expanded ? classes.typographySpacingExpanded : ""}>Uploading your contract...</Typography>
                </>
              }
              {(isUploadComplete && !isUploading) &&
                <>
                  <CloudUploadCompleteIcon className={classes.fileIconReadyForUpload} />
                  <Typography className={expanded ? classes.typographySpacingExpanded : ""}>Your contract has been uploaded, redirecting...</Typography>
                </>
              }
            </>
          }
          {errorMessage && <Typography className={[expanded ? classes.typographySpacingExpanded : classes.typographySpacing, classes.errorMessage].join(" ")}>{errorMessage}</Typography>}
          {(warnMessage && !isUploading && !isUploadComplete) && <Typography className={[expanded ? classes.typographySpacingExpanded : classes.typographySpacing, classes.warnMessage].join(" ")}>{warnMessage}</Typography>}
          {acceptedFiles && (acceptedFiles?.length === 1) && !errorMessage && !isCheckingEncryption &&
            <>
              <FloatingActionButton disabled={isUploading || isUploadComplete} onClick={() => startUpload()} className={[classes.uploadButton].join(' ')} text={getButtonText(isUploading, isUploadComplete)} buttonColor="primary" />
              {(!isUploading && !isUploadComplete) && 
                <Typography variant="subtitle2" className={classes.uploadButtonSubtitle}>To select a different contract, <b style={{color: PROPY_BLUE, cursor: 'pointer'}} {...rootProps}>click here</b>.</Typography>
              }
            </>
          }
        </Paper>
      </div>
    )
};

export default DropContractUpload;