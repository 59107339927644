import { connect, ConnectedProps } from 'react-redux';

import DropContractPage from '../pages/DropContractPage';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  isConsideredMedium: boolean;
  signedIn: boolean;
  consentToCookies: boolean;
}

const mapStateToProps = (state: RootState) => ({
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
  isConsideredMedium: state.isConsideredMedium,
  signedIn: state.signedIn,
  consentToCookies: state.consentToCookies,
})
  
const connector = connect(mapStateToProps, {})
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DropContractPage)