import React from 'react';
import {Routes, Route} from 'react-router-dom';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import NavigationContainer from '../containers/NavigationContainer';
import HomePage from '../pages/HomePage';
import GetStartedSurveyPageContainer from '../containers/GetStartedSurveyPageContainer';
import FlowResetPage from '../pages/FlowResetPage';
import FindAgentPage from '../pages/FindAgentPage';
import TitleAndEscrowPage from '../pages/TitleAndEscrowPage';
import DropContractPageContainer from '../containers/DropContractPageContainer';

import { PROJECT_ROOT } from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    abstractCircles: {
      position: 'absolute',
    },
    abstractCirclesPosition1: {
      top: '20px',
      left: '18px',
    },
    abstractCirclesPosition2: {
      left: 'calc(100% + 15px)',
      transform: 'translateX(-100%)',
      top: '815px',
    },
    abstractCirclesPosition3: {
      top: '1905px',
      left: '-15px',
    }
  }),
);

const PageContainer = () => {

    const classes = useStyles();

    return (
        <NavigationContainer>
            <div className={classes.root}>
                <Routes>
                    <Route path={`${PROJECT_ROOT}/`} element={<HomePage/>} />
                    <Route path={`${PROJECT_ROOT}/title-and-escrow`} element={<TitleAndEscrowPage/>} />
                    <Route path={`${PROJECT_ROOT}/find-an-agent`} element={<FindAgentPage/>} />
                    <Route path={`${PROJECT_ROOT}/drop-contract`} element={<DropContractPageContainer/>} />
                    <Route path={`${PROJECT_ROOT}/get-started`} element={<GetStartedSurveyPageContainer/>} />
                    <Route path={`${PROJECT_ROOT}/get-started/seller/reset`} element={<FlowResetPage flowType="seller" />} />
                    <Route path={`${PROJECT_ROOT}/get-started/buyer/reset`} element={<FlowResetPage flowType="buyer" />} />
                    <Route path={`${PROJECT_ROOT}/get-started/agent/reset`} element={<FlowResetPage flowType="agent" />} />
                    <Route path={`${PROJECT_ROOT}/get-started/:userType`} element={<GetStartedSurveyPageContainer/>} />
                    <Route path={`${PROJECT_ROOT}/get-started/:userType/:onboardingStep`} element={<GetStartedSurveyPageContainer/>} />
                </Routes>
            </div>
        </NavigationContainer>
    )
}

export default PageContainer;