import React, { useState } from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FloatingActionButton from './FloatingActionButton';
import AgentLeaderboardEntry from './AgentLeaderboardEntry';

import JuliethRomero from '../assets/img/agent-images/Julieth-Romero.jpg';
import AshleyReising from '../assets/img/agent-images/Ashley-Reising.jpg';
import AudreyKatherineMyers from '../assets/img/agent-images/Audrey-Myers.jpg';
import BenSwanson from '../assets/img/agent-images/Ben-Swanson.jpg';
import MarkAntonowsky from '../assets/img/agent-images/Mark-Antonowsky.jpg';
import MartyGriffith from '../assets/img/agent-images/Marty-Griffith.jpg';
import TresHall from '../assets/img/agent-images/Tres-Hall.jpg';
import Placeholder from '../assets/img/agent-images/placeholder.jpg';

import { PropsFromRedux } from '../containers/AgentLeaderboardContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    inner: {
      width: 1000,
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    showMoreButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    showMoreButton: {
      marginTop: 16,
    }
  }),
);

interface IAgentLeaderboard {
  initialDelay?: number
  hideHeading?: boolean
}

interface ILeaderboardDataRecord {
  rank: string
  name: string
  description: string
  amount: number | string
  profile_link: string | false
  image_link?: string
}

const leaderboardData: ILeaderboardDataRecord[] = [
  {
    rank: '1',
    name: 'Julieth Romero',
    description: 'La Rosa Realty Kissimmee',
    amount: '6840',
    profile_link: 'https://propy.com/4076391095',
    image_link: JuliethRomero
  },
  {
    rank: '2',
    name: 'Marty Griffith',
    description: 'eXp Realty',
    amount: '1560',
    profile_link: 'https://propy.com/5107383721',
    image_link: MartyGriffith
  },
  {
    rank: '3',
    name: 'Ashley Reising',
    description: 'GemHaus Group',
    amount: '1000',
    profile_link: false,
    image_link: AshleyReising
  },
  {
    rank: '4',
    name: 'Audrey Katherine Myers',
    description: 'eXp Realty',
    amount: '1000',
    profile_link: 'https://propy.com/6027175415',
    image_link: AudreyKatherineMyers
  },
  {
    rank: '5',
    name: 'Mark Antonowsky',
    description: 'Arizona Real Estate Matters',
    amount: '1000',
    profile_link: 'https://propy.com/6024691111',
    image_link: MarkAntonowsky
  },
  {
    rank: '6',
    name: 'Vicki Trenchard',
    description: 'Coldwell Banker F.I. Grey & Son Residential',
    amount: '1000',
    profile_link: false,
    image_link: Placeholder
  },
  {
    rank: '7',
    name: 'Whitney Freeman',
    description: 'eXp Realty',
    amount: '1000',
    profile_link: 'https://propy.com/agent/66a3974edc3bf0fbafb0c939',
    image_link: Placeholder
  },
  {
    rank: '8',
    name: 'Ben Swanson',
    description: 'Keller Williams Integrity First',
    amount: '1000',
    profile_link: false,
    image_link: BenSwanson
  },
  {
    rank: '9',
    name: 'Bill Miller',
    description: 'RE/MAX Central Realty',
    amount: '1000',
    profile_link: 'https://propy.com/agent/669575a18d513fc2819f46f0',
    image_link: Placeholder
  },
  {
    rank: '10',
    name: 'Tres Hall',
    description: '',
    amount: '1000',
    profile_link: 'https://propy.com/agent/6668574487579715df7b762a',
    image_link: TresHall
  }
];

let topThreeColors : {[key: number]: string} = {
  0: "#e7c200",
  1: "#757575",
  2: "#b55200",
}

const AgentLeaderboard = (props: IAgentLeaderboard & PropsFromRedux) => {
    const classes = useStyles();

    const [showFullLeaderboard, setShowFullLeaderboard] = useState(false);

    const {
      initialDelay = 0,
      hideHeading = false,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const titleSpring = useSpring({
      from: {
        opacity: 0,
        top: -25,
      },
      to: {
        opacity: isInView ? 1 : 0,
        top: isInView ? 0 : -25,
      },
      delay: initialDelay
    })

    const showMoreButtonSpring = useSpring({
      from: {
        opacity: 0,
        marginTop: '-35px',
      },
      to: {
        opacity: isInView ? 1 : 0,
        marginTop: isInView ? '0' : '-35px',
      },
      delay: initialDelay + (200 * 4),
    })


    return (
      <div className={classes.root} ref={ref}>
        <div className={classes.inner}>
          {!hideHeading &&
            <animated.div className={classes.headingText} style={titleSpring}>
              <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
                Agent Leaderboard
              </Typography>
              <Typography className={classes.headingSubtitle} variant="h5" component="h4">
                Featuring this month's top performing agents on the Propy platform
              </Typography>
            </animated.div>
          }
          {/* <Grid style={{justifyContent: 'center'}} container columns={12} spacing={2}>
              {leaderboardData.map((leaderboardEntry, index) => 
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AgentLeaderboardEntry
                    activateAnimation={isInView}
                    position={index + 1}
                    name={leaderboardEntry.name}
                    description={leaderboardEntry.description}
                    amount={leaderboardEntry.amount}
                    imageLink={leaderboardEntry.image_link}
                    profileLink={leaderboardEntry.profile_link}
                    baseDelay={initialDelay}
                    animationDelayMultiplier={index + 1}
                    positionCircleColorOverride={topThreeColors[index] ? topThreeColors[index] : undefined}
                  />
                </Grid>
              )}
          </Grid> */}
          <Grid style={{justifyContent: 'center'}} container columns={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {leaderboardData.map((leaderboardEntry, index) => 
                index < 3 &&
                <div style={index > 0 ? {marginTop: 16} : {}}>
                  <AgentLeaderboardEntry
                    activateAnimation={isInView}
                    position={index + 1}
                    name={leaderboardEntry.name}
                    description={leaderboardEntry.description}
                    amount={leaderboardEntry.amount}
                    imageLink={leaderboardEntry.image_link}
                    profileLink={leaderboardEntry.profile_link}
                    baseDelay={initialDelay}
                    animationDelayMultiplier={index + 1}
                    positionCircleColorOverride={topThreeColors[index] ? topThreeColors[index] : undefined}
                    // borderColor={topThreeColors[index] ? topThreeColors[index] : undefined}
                  />
                </div>
              )}
              <>
                <>
                  {!showFullLeaderboard &&
                    <animated.div style={showMoreButtonSpring} className={classes.showMoreButtonContainer}>
                      <FloatingActionButton
                        className={classes.showMoreButton}
                        onClick={() => {setShowFullLeaderboard(true)}}
                        text="Show Full Leaderboard"
                        buttonColor="secondary"
                      />
                    </animated.div>
                  }
                </>
                {showFullLeaderboard && leaderboardData.map((leaderboardEntry, index) => 
                  index >= 3 &&
                  <div style={index >= 3 ? {marginTop: 16} : {}}>
                    <AgentLeaderboardEntry
                      activateAnimation={true}
                      position={index + 1}
                      name={leaderboardEntry.name}
                      description={leaderboardEntry.description}
                      amount={leaderboardEntry.amount}
                      imageLink={leaderboardEntry.image_link}
                      profileLink={leaderboardEntry.profile_link}
                      baseDelay={0}
                      animationDelayMultiplier={index}
                      positionCircleColorOverride={topThreeColors[index] ? topThreeColors[index] : undefined}
                      scaleFactor={0.6}
                    />
                  </div>
                )}
              </>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              {leaderboardData.map((leaderboardEntry, index) => 
                index >= 3 &&
                <div style={index > 3 ? {marginTop: 16} : {}}>
                  <AgentLeaderboardEntry
                    activateAnimation={isInView}
                    position={index + 1}
                    name={leaderboardEntry.name}
                    description={leaderboardEntry.description}
                    amount={leaderboardEntry.amount}
                    imageLink={leaderboardEntry.image_link}
                    profileLink={leaderboardEntry.profile_link}
                    baseDelay={initialDelay}
                    animationDelayMultiplier={index + 1}
                    positionCircleColorOverride={topThreeColors[index] ? topThreeColors[index] : undefined}
                    scaleFactor={0.6}
                  />
                </div>
              )}
            </Grid> */}
          </Grid>
        </div>
      </div>
    )
};

export default AgentLeaderboard;