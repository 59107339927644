import { User, UserManager, UserManagerSettings, OidcClientSettings, WebStorageStateStore } from 'oidc-client-ts';

import {
  API_IDENTITY_SERVER_URL,
  API_IDENTITY_SERVICE_CLIENT_ID,
  BASE_URL,
} from '../constants';

const accessTokenKey = 'propyAccessToken';
const refreshTokenKey = 'propyRefreshToken';
const expiryTokenKey = 'propyExpiryToken';
// const walletVerificationDataKey = 'propyWalletVerificationData';

interface ISignInRedirectArgs {
  extraQueryParams: {
    login_hint: string;
    acr_values: string[];
  };
  useReplaceToNavigate?: boolean;
}

export abstract class PropyAuthority {
  abstract signinRedirect(args?: ISignInRedirectArgs): void;
  abstract signinSilent(args?: ISignInRedirectArgs): Promise<User | null>;
  abstract refreshTokens(): Promise<any>;
  abstract checkURLForSignInCallback(): Promise<any>;
  abstract getTokens(): Promise<{ expired: boolean }>;
  abstract startSilentRenew(): void;
  abstract onTokensLoaded(
      callback: (u: { expired: boolean; access_token: string; refresh_token: string; expires_in: number }) => void
  ): void;
  abstract signoutRedirect(): Promise<any>;
  abstract clearTokens(): Promise<any>;
  abstract setUser(settings: any): any;
}

export class IdentityServerPropyAuthority implements PropyAuthority {
  userManager: UserManager;

  constructor() {
      const isConfig = getIdentityServerConfig();
      this.userManager = new UserManager(isConfig);
  }

  setUser(settings: any) {
      this.userManager.storeUser(new User(settings));
  }

  signinRedirect(args: OidcClientSettings & ISignInRedirectArgs) {
      // use replace so that users can hit back from authority login page and NOT be re-redirected - rather go back to where they started -
      args = { ...args, useReplaceToNavigate: true };
      this.userManager.signinRedirect(args);
  }
  refreshTokens() {
    return this.userManager.signinSilent();
  }
  signinSilent(args: OidcClientSettings & ISignInRedirectArgs) {
    return this.userManager.signinSilent(args);
  }
  checkURLForSignInCallback() {
      return this.userManager.signinRedirectCallback();
  }
  //@ts-ignore
  getTokens() {
      return this.userManager.getUser();
  }
  startSilentRenew() {
      this.userManager.startSilentRenew();
  }
  onTokensLoaded(callback: (u: any) => void) {
      this.userManager.events.addUserLoaded((u) => callback(u));
  }
  signoutRedirect() {
      return this.userManager.signoutRedirect();
  }
  clearTokens() {
      return this.userManager.removeUser();
  }
}

export function getIdentityServerConfig(): UserManagerSettings & { monitorSession?: boolean } {
  return {
      authority: API_IDENTITY_SERVER_URL,
      client_id: API_IDENTITY_SERVICE_CLIENT_ID,
      redirect_uri: BASE_URL + '/token/login',
      response_type: 'code',
      scope: 'openid profile propyserverapi propytransactionplatformapi offline_access',
      post_logout_redirect_uri: BASE_URL,
      loadUserInfo: false, // we rely on Listing Platform API for user info so no need to fetch it via Identity Server
      monitorSession: false,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
  };
}

export const storeAuthTokens = (accessToken: string, refreshToken: string, expiresInSeconds: number, authTime: string) => {
  let tokenExpireTimestamp = ((Number(authTime) + expiresInSeconds) * 1000).toString();
  localStorage.setItem(accessTokenKey, accessToken);
  localStorage.setItem(refreshTokenKey, refreshToken);
  localStorage.setItem(expiryTokenKey, tokenExpireTimestamp);
}

export const signOutClearAuthTokens = async () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem(expiryTokenKey);
  let idServer = new IdentityServerPropyAuthority();
  idServer.clearTokens();
}

export interface PropyAuthorityConfigType {
  apiIdentityServerUrl: string; // 'https://localhost:5000' | 'https://dev.is.propy.com' | 'https://qa.is.propy.com' ...
  apiIdentityServiceClientId: string; // 'propy-web' | 'test-propy-web'
  baseUrl: string;
}