import React, { useState, useEffect, useRef } from 'react';
// import { createPortal } from 'react-dom';

import { Link } from "react-router-dom";

import { animated, useSpring } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuEntryCircle from '@mui/icons-material/Circle';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import GetStartedIcon from '@mui/icons-material/Flag';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';

import LogoDarkMode from '../assets/svg/propy-dark-mode.svg'
import LogoLightMode from '../assets/svg/propy-light-mode.svg'

import ExternalLink from './ExternalLink';
import { PropsFromRedux } from '../containers/NavigationTopBarContainer';

import FloatingActionButton from './FloatingActionButton';
import LinkWrapper from './LinkWrapper';

import { 
  PROPY_BLUE,
  PROJECT_ROOT,
  MENU_ENTRIES,
  BASE_URL,
} from '../constants';

import {
  IMenuTopLevelEntry
} from '../interfaces';

import {
  useCurrentPath,
} from '../hooks';

import {
  signOutClearAuthTokens,
} from '../services/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'sticky',
      top: 0,
      zIndex: 10,
    },
    menuEntryContainerOuter: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center'
    },
    menuEntryContainerInner: {
      display: 'flex',
    },
    menuEntryTopLevel: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    menuEntryTopLevelExpandIcon: {
      opacity: 0.4
    },
    menuEntryTopLevelIcon: {
      marginRight: theme.spacing(1),
      color: "#37a6fb",
    },
    menuEntryTopLevelIconLight: {
      marginRight: theme.spacing(1),
      opacity: 0.4,
    },
    menuEntrySpacer: {
      marginRight: theme.spacing(6),
    },
    menuEntrySmallSpacer: {
      marginRight: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      cursor: 'pointer',
    },
    margin: {
      margin: theme.spacing(1),
    },
    appBar: {
      // borderTop: '1px solid #dfe4e9',
      // borderBottom: '1px solid #dfe4e9',
      transition: 'all 0.2s ease-in-out',
    },
    appBarMobile: {
      padding: 5,
      background: '#ffffffbd',
      position: 'fixed',
      display: 'flex',
      bottom: '0px',
      right: '0px',
      left: '0px',
    },
    appBarNotStuck: {
      background: 'transparent',
      boxShadow: 'none',
    },
    appBarStuck: {
      background: '#ffffffbd',
      backdropFilter: 'blur(10px)',
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)',
      borderRadius: 16,
    },
    expandedMenuEntry: {
      color: PROPY_BLUE,
      opacity: 1,
    },
    menuFlyout: {
      position: 'absolute',
      width: '100%',
      backgroundColor: '#000000e8',
      backdropFilter: 'blur(5px)',
      overflow: 'hidden',
      color: 'white',
      top: '63px',
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
      borderTopRightRadius: 16,
      borderTopLeftRadius: 16,
    },
    menuFlyoutInner: {
      position: 'relative',
      border: `5px solid ${PROPY_BLUE}`,
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
      borderTopRightRadius: 16,
      borderTopLeftRadius: 16,
    },
    menuFlyoutTitleContainer: {
      maxWidth: 400,
      marginRight: theme.spacing(4),
    },
    mobileLaunchAppButton: {
      width: '100%',
    },
    menuFlyoutCloseContainer: {
      position: 'absolute',
      left: 'calc(100% - 15px)',
      transform: 'translateX(-100%)',
      top: '15px',
    },
    menuEntryListColumn: {
      paddingTop: 0,
    },
    menuListEntry: {
      display: 'flex',
      alignItems: 'center',
      color: 'inherit',
    },
    menuListEntryCircle: {
      marginRight: theme.spacing(1),
      width: 6,
    },
    menuListEntrySpacer: {
      marginTop: theme.spacing(2),
    },
    menuListEntryText: {
      fontWeight: 'bold',
      fontSize: '1.15rem',
    },
    menuListEntryFeatured: {
      color: PROPY_BLUE,
    },
    menuColumns: {
      marginTop: 0,
      alignSelf: 'center',
    },
    personaButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    navLink: {
      fontSize: '1.1rem',
    }
  }),
);

const NavigationTopBar = (props: PropsFromRedux) => {
  const classes = useStyles();

  const pathMatch = useCurrentPath();

  let {
    isConsideredMobile,
    showLeftMenu,
    setSignedIn,
    signedIn,
  } = props;

  const [localDarkMode, setLocalDarkMode] = useState(props.darkMode)
  const [bottomConnectButton, setBottomConnectButton] = useState(false);
  const [delayHandler, setDelayHandler] = useState<any>(null);

  const [expandedMenuEntryId, setExpandedMenuEntryId] = useState("");
  const [currentFlyout, setCurrentFlyout] = useState<undefined | IMenuTopLevelEntry>();

  // const [springs] = useSprings(
  //   currentFlyout?.innerEntries?.length ? currentFlyout?.innerEntries?.length : 0,
  //   (springIndex: number) => ({
  //     from: {
  //       opacity: 0,
  //       transform: 'translateY(-35px)',
  //     },
  //     to: {
  //       opacity: 1,
  //       transform: currentFlyout?.id === expandedMenuEntryId ? 'translateY(0px)' : 'translateY(-25px)',
  //     },
  //     delay: 400 + (200 * springIndex),
  //   }),
  //   [currentFlyout, expandedMenuEntryId]
  // )

  useEffect(() => {
    if(expandedMenuEntryId !== "") {
      let matchedExpandedMenuEntry = MENU_ENTRIES.filter((item) => item.id === expandedMenuEntryId);
      if(matchedExpandedMenuEntry && matchedExpandedMenuEntry.length > 0) {
        let matched = matchedExpandedMenuEntry[0];
        setCurrentFlyout(matched);
      }
    } else {
      setCurrentFlyout(undefined);
    }
  }, [expandedMenuEntryId])

  const handleMouseEnter = (menuEntryId: string, index: number) => {
      setDelayHandler(setTimeout(() => {
        setExpandedMenuEntryId(menuEntryId);
      }, expandedMenuEntryId === "" ? 100 : 0))
  }

  const handleMouseLeave = () => {
    clearTimeout(delayHandler)
  }

  const flyoutContainerSpring = useSpring({
    from: {
      height: 0,
      opacity: 0,
    },
    to: {
      height: currentFlyout ? currentFlyout.desktopFlyoutHeight : 0,
      opacity: currentFlyout ? 1 : 0,
    },
    delay: 0,
  })

  useEffect(() => {
    if(!isConsideredMobile) {
      setBottomConnectButton(false);
    } else if (isConsideredMobile) {
      setBottomConnectButton(true);
    }
  }, [isConsideredMobile]);

  useEffect(() => {
    setLocalDarkMode(props.darkMode)
  }, [props.darkMode])

  const [stuck, setStuck] = useState(false)
  const appBarCollisionRef = useRef<HTMLDivElement>(null);

  // const classes = stuck ? stuckClasses : unstuckClasses
  // const styles = stuck ? stuckStyles : unstuckStyles

  const inlineStyles = {
    top: -1,
    // ...styles
  }

  useEffect(() => {
    const cachedRef = appBarCollisionRef.current
    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      { threshold: [1] }
    )
    if(cachedRef) {
      observer.observe(cachedRef)
    }
    return () => {
      if(cachedRef) {
        observer.unobserve(cachedRef)
      }
    }
  }, [appBarCollisionRef])

  useEffect(() => {
    function checkUserData() {
      if(setSignedIn) {
        const accessToken = localStorage.getItem('propyAccessToken');
        // const refreshToken = localStorage.getItem('propyRefreshToken');
    
        if (accessToken) {
          setSignedIn(true);
        } else {
          setSignedIn(false);
        }
      }
    }
  
    window.addEventListener('storage', checkUserData)
  
    return () => {
      window.removeEventListener('storage', checkUserData)
    }
  }, [setSignedIn])

  const signOut = () => {
    setSignedIn(false);
    signOutClearAuthTokens();
  }
  
  return (
    <div className={classes.root}>
      <div>
      {/*  We use this appBarCollisionRef div to detect a collision with the top of the screen, then we set the appbar to its sticky state */}
      <div ref={appBarCollisionRef} style={{top: -1, position: 'relative'}}/>
      <AppBar position="relative" className={[classes.appBar, (stuck || (pathMatch !== `${PROJECT_ROOT}/`)) ? classes.appBarStuck : classes.appBarNotStuck].join(' ')} style={inlineStyles}>
        <Toolbar style={{color: props.darkMode ? 'white' : 'black'}}>
          <Link to={`${PROJECT_ROOT}/`} onMouseEnter={() => setExpandedMenuEntryId('')} style={{textDecoration: 'none', height: 28}}>
            <img height={'28px'} style={{marginRight: '10px', cursor: 'pointer'}} src={localDarkMode ? LogoDarkMode : LogoLightMode} alt="Propy logo" />
          </Link>
          <div className={classes.menuEntryContainerOuter}>
            {!isConsideredMobile &&
              <div className={classes.menuEntryContainerInner}>
                {MENU_ENTRIES.map((entry, index) => 
                  <LinkWrapper key={`desktop-menu-${index}`} link={entry.link ? entry.link : ''} external={entry.external}>
                    <animated.div
                      onMouseEnter={() => entry.innerEntries ? handleMouseEnter(entry.id, index) : setExpandedMenuEntryId("")}
                      onMouseLeave={() => handleMouseLeave()}
                      onTouchStart={() => entry.innerEntries ? setExpandedMenuEntryId(entry.id) : setExpandedMenuEntryId("")}
                      // style={springs[index]}
                      className={[
                        'hovered-propy-blue',
                        classes.menuEntryTopLevel,
                        index < (MENU_ENTRIES.length - 1) ? classes.menuEntrySpacer : '',
                        expandedMenuEntryId === entry.id ? classes.expandedMenuEntry : '',
                      ].join(" ")}
                    >
                      {!entry.innerEntries && entry.Icon &&
                        <entry.Icon className={classes.menuEntryTopLevelIcon}/>
                      }
                      {!entry.innerEntries && entry.img &&
                        <img alt={`${entry.label} icon`} src={entry.img} className={classes.menuEntryTopLevelIcon}/>
                      }
                      <Typography className={classes.navLink} variant="h6">
                        {entry.label}
                      </Typography>
                      {entry.innerEntries && expandedMenuEntryId !== entry.id &&
                        <ArrowDown className={classes.menuEntryTopLevelExpandIcon} />
                      }
                      {entry.innerEntries && expandedMenuEntryId === entry.id &&
                        <ArrowUp />
                      }
                    </animated.div>
                  </LinkWrapper>
                )}
              </div>
            }
          </div>
          {!bottomConnectButton && 
            <>
              <div
                className={[
                  classes.menuEntryTopLevel,
                  classes.menuEntrySmallSpacer,
                  'hovered-propy-blue',
                ].join(" ")}
              >
                <LinkWrapper link={'/drop-contract'} external={false} className={classes.menuListEntry}>
                  <GetStartedIcon className={classes.menuEntryTopLevelIconLight}/>
                  <Typography className={classes.navLink} variant="h6">
                    Open Escrow
                  </Typography>
                </LinkWrapper>
              </div>
              {signedIn
                ?
                  <>
                    <div
                      className={[
                        classes.menuEntryTopLevel,
                        classes.menuEntrySmallSpacer,
                        'hovered-propy-blue',
                      ].join(" ")}
                      onClick={() => signOut()}
                    >
                      <LogoutIcon className={classes.menuEntryTopLevelIconLight}/>
                      <Typography className={classes.navLink} variant="h6">
                        Sign Out
                      </Typography>
                    </div>
                    <ExternalLink href={`${BASE_URL}/dashboard`}>
                      <FloatingActionButton
                        text="Go To Platform"
                      />
                    </ExternalLink>
                  </>
                :
                  <ExternalLink href={`${BASE_URL}/login`}>
                    <FloatingActionButton
                      text="Sign in"
                    />
                  </ExternalLink>
              }
              {/* <Web3ModalButton/> */}
            </>
          }
          {/* <IconButton
            color="inherit"
            onClick={() => props.setDarkMode(!localDarkMode)}
            aria-label="delete"
            className={classes.margin}
            size="large">
            {localDarkMode ? <LightModeIcon/> : <DarkModeIcon />}
          </IconButton> */}
          {isConsideredMobile &&
            <IconButton
              onClick={() => props.setShowLeftMenu(!showLeftMenu)}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              size="large">
              <MenuIcon />
            </IconButton>
          }
        </Toolbar>
      </AppBar>
      {/* {bottomConnectButton && 
        createPortal(
          <div className={classes.appBarMobile}>
            {signedIn ?
              <ExternalLink style={{width: '100%'}} href={`${BASE_URL}/dashboard`}>
                <FloatingActionButton
                  text="Go To Platform"
                  className={classes.mobileLaunchAppButton}
                />
              </ExternalLink>
            : 
              <ExternalLink style={{width: '100%'}} href={`${BASE_URL}/login`}>
                <FloatingActionButton
                  text="Sign in"
                  className={classes.mobileLaunchAppButton}
                />
              </ExternalLink>
            }
          </div>
        , document.body)
      } */}
      {!isConsideredMobile &&
        <animated.div
          onMouseLeave={() => setExpandedMenuEntryId('')}
          className={classes.menuFlyout}
          style={{
            ...flyoutContainerSpring
          }}
        >
          <div className={classes.menuFlyoutInner}>
            {currentFlyout?.desktopFlyoutTitle &&
              <div className={classes.menuFlyoutTitleContainer}>
                <div>
                  <Typography variant="h4" style={{fontWeight: 'bold'}}>
                    {currentFlyout?.desktopFlyoutTitle}
                  </Typography>
                  {currentFlyout?.desktopFlyoutDescription &&
                    <Typography variant="h5">
                      {currentFlyout?.desktopFlyoutDescription}
                    </Typography>
                  }
                  {currentFlyout?.ctaText && currentFlyout?.ctaLink &&
                    <Link to={`${currentFlyout.ctaLink}`} onClick={() => setExpandedMenuEntryId('')} style={{textDecoration: 'none'}}>
                      <FloatingActionButton className={[classes.personaButton].join(' ')} text={currentFlyout.ctaText} buttonColor="primary" />
                    </Link>
                  }
                </div>
              </div>
            }
            {currentFlyout?.innerEntries && currentFlyout?.innerEntries?.length > 0 &&
              <Grid container className={classes.menuColumns} spacing={2}>
                {currentFlyout?.innerEntries?.map((column, index) => 
                  <Grid item md={4} key={`desktop-menu-entry-${index}`} className="disable-top-padding">
                    {/* <animated.div style={springs[index]}> */}
                      {column?.map((row, rowIndex) => 
                        <div key={`desktop-menu-entry-${index}-${rowIndex}`} className={[(rowIndex > 0) ? classes.menuListEntrySpacer : '', row.featuredEntry ? classes.menuListEntryFeatured : ''].join(' ')}>
                          <LinkWrapper onClick={() => setExpandedMenuEntryId('')} link={row.link} external={row.external} className={classes.menuListEntry}>
                            <MenuEntryCircle className={classes.menuListEntryCircle}/>
                            <Typography className={classes.menuListEntryText} variant="body1">
                              {row.label}
                            </Typography>
                          </LinkWrapper>
                        </div>
                      )}
                    {/* </animated.div> */}
                  </Grid>
                )}
              </Grid>
            }
            <div className={[classes.menuFlyoutCloseContainer, "interactive-hover-opacity"].join(' ')} onClick={() => setExpandedMenuEntryId('')}>
              <CloseIcon style={{width: 32, height: 32}}/>
            </div>
          </div>
        </animated.div>
      }
      </div>
    </div>
  );
}

export default NavigationTopBar