import React, { useRef }  from 'react';

import { Link } from 'react-router-dom';

import ReCAPTCHA from "react-google-recaptcha";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import CuriousIcon from '@mui/icons-material/KeyboardArrowRight';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import Card from '@mui/material/Card';

import ExternalLink from './ExternalLink';

import FloatingActionButton from './FloatingActionButton';
import DropContractUploadContainer from '../containers/DropContractUploadContainer';

import { 
  PROJECT_ROOT,
  RECAPTCHA_KEY,
  // BASE_URL, 
  // PROPY_BLUE
} from '../constants';

import { PropsFromRedux } from '../containers/PersonaSelectionCardContainer';

// import {
//   useLatestUserNotifications,
// } from '../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(4),
    },
    card: {
      width: '100%',
      maxWidth: 410,
      backgroundColor: '#ffffffa1',
      backdropFilter: 'blur(5px)',
    },
    cardPaddingDesktop: {
      padding: theme.spacing(2),
    },
    cardPaddingMobile: {
      padding: theme.spacing(1),
    },
    sectionSpacer: {
      marginBottom: theme.spacing(2)
    },
    propyLogo: {
      width: 40,
      marginRight: theme.spacing(2),
    },
    logoTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    personaButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    curiousText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      cursor: 'pointer',
      fontSize: '0.95rem'
    },
    sampleNotificationContainer: {
      backgroundColor: '#ffffffdb',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
      borderRadius: 16,
    },
    sampleNotificationContainerDesktopPadding: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
    sampleNotificationContainerMobilePadding: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    dropContractZone: {
      marginBottom: theme.spacing(1),
    },
    sampleNotificationText: {
      fontWeight: 'bold',
      fontSize: '0.95rem'
    },
    recaptchaContainer: {
      display: 'none',
    }
  }),
);

interface IPersonaSelectionCard {
  showDropContractWhenSignedIn?: boolean,
}

const PersonaSelectionCard = (props: PropsFromRedux & IPersonaSelectionCard) => {
    const classes = useStyles();

    let {
      isConsideredMobile,
      signedIn,
      showDropContractWhenSignedIn = false,
    } = props;

    const recaptchaRef = useRef(null);

    // const latestUserNotifications = useLatestUserNotifications(signedIn);

    return (
      <div className={classes.root}>
        <Card elevation={3} className={[classes.card, isConsideredMobile ? classes.cardPaddingMobile : classes.cardPaddingDesktop].join(" ")}>
          {(!signedIn || (signedIn && !showDropContractWhenSignedIn)) &&
            <>
              <div className={[classes.logoTextContainer, classes.sectionSpacer].join(' ')}>
                <Typography style={{fontWeight: 'bold'}} variant={isConsideredMobile ? "h5" : "h4"}>
                  Get Started
                </Typography>
              </div>
              <Link id="get-started-agent-button" to={`${PROJECT_ROOT}/get-started/agent`} style={{textDecoration: 'none'}}>
                <FloatingActionButton className={[classes.personaButton].join(' ')} text="I'm an Agent" buttonColor="secondary" />
              </Link>
              <Link id="get-started-seller-button" to={`${PROJECT_ROOT}/get-started/seller`} style={{textDecoration: 'none'}}>
                <FloatingActionButton className={[classes.personaButton].join(' ')} text="I'm a Seller" buttonColor="secondary" />
              </Link>
              <Link id="get-started-buyer-button" to={`${PROJECT_ROOT}/get-started/buyer`} style={{textDecoration: 'none'}}>
                <FloatingActionButton className={[classes.personaButton].join(' ')} text="I'm a Buyer" buttonColor="secondary" />
              </Link>
              <ExternalLink href="https://propy.com/browse/about/">
                <Typography className={[classes.curiousText, 'flex-center-all', 'hover-underline'].join(' ')} variant="subtitle1">
                  I'm just curious <CuriousIcon/>
                </Typography>
              </ExternalLink>
            </>
          }
          {(signedIn && showDropContractWhenSignedIn) &&
            <>
              <div className={[classes.logoTextContainer, classes.sectionSpacer].join(' ')}>
                <Typography style={{fontWeight: 'bold'}} variant={isConsideredMobile ? "h5" : "h4"}>
                  Drop a contract
                </Typography>
              </div>
              <div className={[classes.dropContractZone].join(" ")}>
                <DropContractUploadContainer recaptchaRef={recaptchaRef} />
              </div>
              <div 
                className={classes.recaptchaContainer}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  badge="bottomleft"
                  sitekey={RECAPTCHA_KEY}
                />
              </div>
              {/* <div className={[classes.sampleNotificationContainer, isConsideredMobile ? classes.sampleNotificationContainerMobilePadding : classes.sampleNotificationContainerDesktopPadding].join(" ")}>
                {latestUserNotifications?.[0]?.message &&
                  <>
                    <NotificationsActiveIcon style={{marginBottom: 8, color: PROPY_BLUE}} />
                    <Typography className={[classes.sampleNotificationText].join(' ')} variant="subtitle1">
                      {latestUserNotifications[0].message}
                    </Typography>
                  </>
                }
                {!latestUserNotifications?.[0]?.message &&
                  <>
                    <VerifiedUserIcon  style={{marginBottom: 8, color: PROPY_BLUE}} />
                    <Typography className={[classes.sampleNotificationText].join(' ')} variant="subtitle1">
                      Navigate to Propy's Platform to manage your account-related activities
                    </Typography>
                  </>
                }
                <ExternalLink style={{width: '100%'}} href={`${BASE_URL}/dashboard`}>
                  <FloatingActionButton className={[classes.personaButton].join(' ')} text="Go To Platform" buttonColor="primary" />
                </ExternalLink>
              </div> */}
            </>
          }
        </Card>
      </div>
    )
};

export default PersonaSelectionCard;