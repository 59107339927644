import React, { useState } from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { animated, useSpring } from '@react-spring/web'

import LinkWrapper from './LinkWrapper';

import {
  PROPY_BLUE,
} from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 64,
      width: '100%',
      maxWidth: 232,
      borderRadius: 11,
      padding: theme.spacing(1.5),
      paddingRight: theme.spacing(2.5),
      display: 'flex',
      alignItems: 'center',
    },
    iconContainer: {
      height: 43,
      width: 43,
      backgroundColor: PROPY_BLUE,
      borderRadius: 5,
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    springContainer: {
      position: 'relative',
      width: 'auto',
      height: 'auto',
      backgroundColor: 'transparent',
      display: 'flex',
    },
    uploadIcon: {
      color: '#ffffff',
      height: '30px',
      width: '30px',
    },
  }),
);

const DropContractButton = () => {

    const classes = useStyles();

    const [buttonActive, setButtonActive] = useState(false);

    const uploadIconSpring = useSpring({
      from: {
        opacity: '0.8',
      },
      to: {
        opacity: buttonActive ? '1' : '0.8',
      },
      delay: 0,
    })

    const iconContainerSpring = useSpring({
      from: {
        backgroundColor: PROPY_BLUE,
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
      },
      to: {
        backgroundColor: buttonActive ? "#32a6ff" : PROPY_BLUE,
        boxShadow: buttonActive ? '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)' : '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
      },
      delay: 0,
    })

    const buttonSpring = useSpring({
      from: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'
      },
      to: {
        backgroundColor: buttonActive ? '#f1f1f1' : '#ffffff',
        boxShadow: buttonActive ? '0px 5px 5px -1px rgba(0, 0, 0, 0.3), 0px 9px 10px 0px rgba(0, 0, 0, 0.18), 0px 2px 18px 0px rgba(0, 0, 0, 0.16)' : '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'
      },
      delay: 0,
    })

    return (
      <LinkWrapper className="inline-block" link={'/drop-contract'} external={false}>
        <animated.div 
          style={buttonSpring}
          className={classes.root}
          onMouseEnter={() => setButtonActive(true)} 
          onMouseLeave={() => setButtonActive(false)}
        >
            <animated.div style={iconContainerSpring} className={classes.iconContainer}>
              <animated.div style={uploadIconSpring} className={classes.springContainer}>
                <UploadFileIcon className={classes.uploadIcon} />
              </animated.div>
            </animated.div>
            <Typography variant="h6">Drop a contract</Typography>
        </animated.div>
      </LinkWrapper>
    )
};

export default DropContractButton;