import React, { useRef } from 'react';

import ReCAPTCHA from "react-google-recaptcha";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import OnboardingFlowCookieGateContainer from '../containers/OnboardingFlowCookieGateContainer';

import { PropsFromRedux } from '../containers/DropContractPageContainer';
import DropContractUploadContainer from '../containers/DropContractUploadContainer';

import OnboardingFlowWrapper from '../components/OnboardingFlowWrapper';

import {
  RECAPTCHA_KEY,
} from '../constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%',
    },
    card: {
        minWidth: 275,
        marginBottom: 15
    },
    title: {
        fontSize: 14,
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '250px',
    },
    exampleImage: {
        width: '30%',
        margin: theme.spacing(4),
    },
    innerContent: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      paddingTop: theme.spacing(10),
      borderRadius: 16,
      maxWidth: '100%',
    },
    logoTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dropContractZone: {
      marginBottom: theme.spacing(1),
    },
    sectionSpacer: {
      marginBottom: theme.spacing(3)
    },
    personaCardContainerMobile: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    recaptchaContainer: {
      display: 'none',
    }
  }),
);

const DropContractPage = (props: PropsFromRedux) => {
    const classes = useStyles();

    let {
      consentToCookies,
      isConsideredMobile,
    } = props;

    const recaptchaRef = useRef(null);

    return (
      <OnboardingFlowWrapper>
        <Container maxWidth="lg" className={classes.container}>
          {!consentToCookies &&
            <OnboardingFlowCookieGateContainer />
          }
          {consentToCookies &&
            <>
              <div className={classes.personaCardContainerMobile}>
                <div className={[classes.logoTextContainer, classes.sectionSpacer].join(' ')}>
                  <Typography style={{fontWeight: 'bold'}} variant={isConsideredMobile ? "h5" : "h4"}>
                    Drop a contract
                  </Typography>
                </div>
                <div className={[classes.dropContractZone].join(" ")}>
                  <DropContractUploadContainer recaptchaRef={recaptchaRef} expanded={true} />
                </div>
              </div>
              <div 
                className={classes.recaptchaContainer}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  badge="bottomleft"
                  sitekey={RECAPTCHA_KEY}
                />
              </div>
            </>
          }
        </Container>
      </OnboardingFlowWrapper>
    )
};

export default DropContractPage;